h2 {
  color: rgb(4, 4, 91);
  font-family: Arial, Helvetica, sans-serif;
  text-align: left;
  font-size: 20px;
}

p {
  text-align: left;
  letter-spacing: 2px;
  font-size: 18px;
}

.one-faq {
  width: 80%; /* Adjust the width as needed */
  margin: 0 auto; /* Center the container horizontally */
  padding: 10px;
  border: 1px dotted #4eadca; 
  margin-bottom: 20px; /* Add space between FAQs */
  background-color: rgb(243, 202, 225);
}

html, body {
  height: 100%;
  background-color: rgb(243, 202, 225);
}
