form {
  max-width: 400px;
  position:relative; 
  color: rgb(4, 4, 91);
}

.form-field {
  padding: 10px;
}

.form input {
  min-width: 100%;
  max-width: 350px;
  min-height: 50px;
  padding: 10px;
  font-size: 18px;
  letter-spacing: 2px;
  font-family: Arial, Helvetica, sans-serif;
}

.form label {
  padding-bottom: 5px;
  display: inline-block;
}

.form textarea {
  min-width: 100%;
  max-width: 350px;
  min-height: 150px;
  padding: 10px;
  font-size: 18px;
  letter-spacing: 2px;
  font-family: Arial, Helvetica, sans-serif;
}

.form button {
  min-width: 100%;
  max-width: 350px;
  min-height: 50px;
  padding: 10px;
  font-size: 18px;
  letter-spacing: 2px;
  background-color: rgb(27, 174, 171);
  border-color:rgb(27, 174, 171);
  color: white;

  font-family: Arial, Helvetica, sans-serif;
}
