.flash-container .flash:first-child .flash-image{
  margin-top: 20px;
}

/* .flash-container .flash:last-child .flash-image {
  margin-bottom: 20px;
} */

.flash-image {
  width: 255px;
  height: 255px; 
  margin: 10px;
  background-size: cover;
}

.flash {
  display: inline-block;
  position: relative;
  margin-left: 3px;
  margin-right: 3px;
}

.container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  text-align: center;
  padding-bottom: 10px;
}