.one-about {
  display: flex;
  justify-content: center; /* Center content horizontally */
}

.content-container {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center content vertically */
  text-align: center;
  max-width: 300px; 
  border: 1px dotted #4eadca;
  padding: 20px; /* Add padding to create space between content and border */
}

.one-about img {
  width: 100%; /* Ensure the image fills its container */
  max-width: 280px; /* Limit the maximum width of the image */
  height: auto; /* Maintain aspect ratio */
  border: 1px solid rgb(231, 106, 218);
  margin-bottom: 20px; /* Add space below the image */
}

p {
  margin-top: 10px; /* Add some space between image and text */
}
