* {
  box-sizing: border-box;
}

body {
  margin: 0;
}

.nav {
  background-color: white;
  color: rgb(255, 255, 255);
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  grid-gap: .5rem;
  padding: 0rem;
  font-family: 'Trebuchet MS', sans-serif;
  font-style: italic;
  letter-spacing: 1px;
  position: relative;
  background-color: rgb(243, 202, 225);
  border: solid;
  border-color: rgb(4, 4, 91);
  font-size: 1rem;
}

.site-title {
  font-size: 2rem;
}

.nav ul {
  padding: 0;
  margin: 1;
  list-style: none;
  display: flex;
  gap: 1rem;
  flex: 1 1 100px;
  flex-flow: row wrap;
  justify-content: center;
}

.nav a {
  color: inherit;
  text-decoration: none;
  height: 100%;
  display: flex;
  align-items: center;
  padding: .25rem;
}

.nav li.active {
  color: rgb(4, 4, 91);
}

.nav li:hover {
  background-color: rgb(235, 198, 232);
}

.head-text {
  position: relative;
}
.text-on-image-1 {
 position: absolute;
 right: 70%;
 left: 12%;
 bottom: 35%;
 top: 38%;
}

.text-on-image-2 {
  position: absolute;
  left: 22%;
  top: 55%;
 }

 .text-on-image-3 {
  position: absolute;
  right: 70%;
  left: 52%;
  bottom: 20%;
  top: 35%;
 }

 .text-on-image-4 {
  position: absolute;
  right: 50%;
  left: 7%;
  bottom: 10%;
  top: 44%;
 }

 .text-on-image-5 {
  position: absolute;
  right: 30%;
  left: 10%;
  bottom: 10%;
  top: 45%;
 }