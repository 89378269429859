.booking {
  padding: 10px;
  color: rgb(4, 4, 91);
  font-size: 18px;
  letter-spacing: 2px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
}

.booking-content {
  border: 1px solid #4eadca;
  border-style: dotted;
  text-align: center;
  padding: 10px;
  margin: 10px;
  font-family: Arial, Helvetica, sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px; 
}

.image-with-border {
  padding: 5px;
  border: 8px dotted rgb(52, 216, 181); 
  border-radius: 8px; 
  margin-bottom: 20px;
}

.button {
  background-color: #4eadca;
  color: white;
  padding-bottom: 20px;
  margin-top: 10px;
  margin-bottom: 70px;
  font-family: Arial, Helvetica, sans-serif;
  letter-spacing: 2px;
  font-size: 18px;
  padding: 14px 25px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  min-width: 80%;
  max-width: 130%;
  min-height: 50px; 
  font-family: Arial, Helvetica, sans-serif;
}

 a:hover, a:active {
  background-color: rgb(204, 166, 240);
}

.custom-image {
  padding: 5px;
  border: 8px dotted rgb(52, 216, 181); 
  border-radius: 8px; 
  margin-bottom: 20px; 
  display: flex;
  flex-direction: column;
  align-items: center; 
}

.custom-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left; 
  font-family: Arial, Helvetica, sans-serif;
}

.custom-form-container h2 {
  margin-bottom: 20px; 
}
