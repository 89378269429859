.container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
}

.artwork {
  display: inline-block;
  margin: 20px;
}

.artwork-image {
  padding: 10px;
  float: left;
}

.artwork-title {
  font-family: Arial, Helvetica, sans-serif;
  text-align: center;
  display: block;
  font-style: italic;
}

.artwork-description {
  float: center;
  text-align: center;
}

